<template>
  <div>
    <label v-if="parseInt(historyCredit.credit) === 0">
      -{{ historyCredit.debit }}
    </label>
    <label v-else>
      {{ historyCredit.credit }}
    </label>
  </div>
</template>
<script>
  export default {
    props: {
      historyCredit: {
        type: Object,
        default: () => {},
      }
    }
  }
</script>

<style scoped>

</style>
